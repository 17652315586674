.cropper {
  width: 350px;
  aspect-ratio: 1 / 1;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.cropper-body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.cropper-control {
  display: flex;
  gap: 1rem;
}

.cropper-controller {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  flex: 0;
}

.cropper-label {
  flex-shrink: 0;
}

.cropper-image {
  height: 200px;
  cursor: pointer;
  width: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cropper-image:hover {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 1);
}

.cropper-add-icon {
  position: absolute;
  right: 6%;
  bottom: 6%;
  border-radius: 50%;
  background-color: white;
}
